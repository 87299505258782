.datepicker-input {
  padding: 1px 9px;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;
  height: 36px;
  width: 100%;
}

.datepicker-input.error {
  border-color: #B91A2E;
}

.datepicker-input:focus {
  outline: none;
}
