html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans';
  font-size: 12px;
  height: 100%;
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  -webkit-appearance: none;
}

html::-webkit-scrollbar:vertical {
  width: 12px;
}

html::-webkit-scrollbar:horizontal {
  height: 12px;
}

html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #d7d7d7;
  background-color: #3c4e5e;
}

html::-webkit-scrollbar-track {
  background-color: #d7d7d7;
}
